import * as React from "react"
import Layout from "../components/layout"
import Slider from "react-slick"
import { Link } from "gatsby"
import Img from "gatsby-image"

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <span className="arrow-prv slick-arrow" onClick={onClick}>
            <i className="icon-chevron-left"></i>
        </span>
    )
};

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <span className="arrow-next slick-arrow" onClick={onClick}>
            <i className="icon-chevron-right"></i>
        </span>
    )
};

const Index = ({ data }) => {

    const sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <Layout>

            <div className="site-wrapper-reveal">

                <Slider {...sliderSettings} className="hero-area hero-slider-five-later slick-initialized slick-slider">

                    <div className="single-hero-slider-five">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="hero-content-wrap">
                                        <div className="hero-text-five mt-lg-5">
                                            <h5 className=" mb-30 small-title">
                                                VIBRA
                                                <br />
                                                ONE
                                            </h5>
                                            <h1>Where Focus
                                                <br />
                                                Meets Productivity</h1>

                                            <div className="button-box section-space--mt_60">
                                                <Link to="/vibra-one/" className="text-btn-normal font-weight--reguler font-lg-p">Explore  <i className="icon-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                        <div className="inner-images" >
                                            <div className="image-one">
                                                <Img fluid={data.circleOne.childImageSharp.fluid} placeholderClassName="img-fluid"></Img>
                                        
                                            </div>
                                            <div className="image-two">
                                                <Img fluid={data.bannerFirstImage.childImageSharp.fluid} alt="silent booth vibra one" className="img-fluid"></Img>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/*
                    <div className="single-hero-slider-five">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="hero-content-wrap">
                                        <div className="hero-text-five mt-lg-5">
                                            <h6 className=" mb-30 small-title">
                                                SILENT
                                                <br/>
                                                BOOTH
                                                <br/>
                                                VIBRA FOUR
                                            </h6>
                                            <h1>Flower Vase
                                                <br/>
                                                Made Of Ceramic</h1>

                                            <div className="button-box section-space--mt_60">
                                                <a
                                                    href="shop-left-sidebar.html"
                                                    className="text-btn-normal font-weight--reguler font-lg-p">Discover Collection
                                                    <i className="icon-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="inner-images">
                                            <div className="image-one">
                                                <img
                                                    src="assets/images/hero/home-full-width-2.png"
                                                    className="img-fluid"
                                                    alt="Image"/>
                                            </div>
                                            <div className="image-two">
                                                <img
                                                    src="assets/images/hero/home-full-width-1.png"
                                                    className="img-fluid"
                                                    alt="Image"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
*/}
                </Slider>

            </div>


            <div className="banner-area-box">
                <div className="container">
                    <div className="row banner-inner-box">
                        <div className="col-lg-6 col-md-6">
                            <div className="banner-images-one mt-30">
                                <Link to="/vibra-one" className="thumbnail">
                                    <Img fluid={data.vibraOneSmallBanner.childImageSharp.fluid} alt="phone booth vibra one"></Img>
                                </Link>
                                <div className="banner-title">
                                    <h3><a href="#">Vibra One </a></h3>
                                    <h6>Silent booth</h6>
                                    <div className="button-box mt-40">
                                        <Link to="/vibra-one" className="text-btn-normal">Discover now <i className="icon-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="banner-images-one mt-30">
                                <div className="thumbnail">
                                    <Img fluid={data.vibraFourSmallBanner.childImageSharp.fluid} alt="office pod vibra two"></Img>
                                </div>
                                <div className="banner-title">
                                    <h3><a href="#">Vibra Four </a></h3>
                                    <h6>Meeting room <br /> (coming soon)</h6>
                                    <div className="button-box mt-40">
                                        <Link to="/contact-us" className="text-btn-normal"> Contact us for more info</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-us-area section-space--ptb_120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-us-content_6 text-center">
                                <h5>Silent booths VIBRA are taking care of the healthy workplace in your company. Whenever you need a quiet place for you to focus or place where you can say your ideas aloud, VIBRA One is here for you.
                                    <br/><br/>
                                    We design private but open spaces, no one wants closed, dark, claustrophobic spots that make you nervous. With providing natural light in Vibra One you always feel calm and ready to triumph.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </Layout>
    )
};

export const query = graphql`
  query {
    vibraOneSmallBanner: file(relativePath: { eq: "home/vibra-one-banner-s.jpg" }){
        childImageSharp {
            fluid(maxWidth: 570, maxHeight: 340) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    vibraFourSmallBanner: file(relativePath: { eq: "home/vibra-four-banner-s.jpg" }){
        childImageSharp {
            fluid(maxWidth: 570, maxHeight: 340) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    bannerFirstImage: file(relativePath: { eq: "hero/VIBRA-ONE-banner.png" }){
        childImageSharp {
            fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    circleOne: file(relativePath: { eq: "hero/home-full-width-3a.png"}){
        childImageSharp {
            fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
  }`


export default Index